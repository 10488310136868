.chatboxdiv {
  display: grid;
  justify-content: center;
}

.chatbox {
  background-color: #fff;
  border-radius: 30px;
  margin: 20px;
  padding: 20px;
  border: 0.5px solid grey;
}

.chatbox-inner {
  border: none;
  width: 100%;
}

.chat-messages {
  height: 40vh;
  width: 100vh;
  overflow: scroll;
  padding-block: 10px;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.sender-message {
  padding: 8px;
  border-radius: 6px;
  background-color: #006944;
  white-space: pre-line;
  width: 50vh;
  height: auto;
  color: #fff;
  margin-left: auto;
}

.bot-message {
  padding: 8px;
  border-radius: 6px;
  background-color: #eeeeee;
  width: 50vh;
  height: auto;
}

.input-div {
  border: 0.5px solid grey;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.chat-input {
  border: none;
  width: 90%;
  height: 40px;
  background: #fff;
}

.chat-input:focus {
  outline: none;
}

@media screen and (max-width: 1030px) {
  .chat-messages {
    width: 70vh;
  }
}

@media screen and (max-width: 740px) {
  .chat-messages {
    width: 40vh;
  }
  .sender-message {
    font-size: 12px;
    width: 38vh;
  }

  .bot-message {
    font-size: 12px;
    width: 38vh;
  }
}

@media screen and (max-width: 460px) {
  .chat-messages {
    width: 30vh;
  }
  .sender-message {
    font-size: 10px;
    width: 28vh;
  }

  .bot-message {
    font-size: 10px;
    width: 28vh;
  }
}

.chat-card-box {
  display: flex;
  box-shadow: 0px 5px 8px rgba(185, 205, 212, 0.53);
  background-color: #fff;
  border-radius: 5px;
}
.chat-card-inter-box {
  height: 348px;
  padding: 10px;
  border: 1px solid rgba(185, 205, 212, 0.53);
  border-radius: 5px;
}

.companion-chat-input {
  height: 38px;
  width: 85%;
  margin-right: 20px;
}

.companion-chat-input:focus {
  outline: none;
}
